import React from 'react';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    number: '1',
    headlineKey: 'steps.step1Headline',
    descriptionKey: 'steps.step1Description',
  },
  {
    number: '2',
    headlineKey: 'steps.step2Headline',
    descriptionKey: 'steps.step2Description',
  },
  {
    number: '3',
    headlineKey: 'steps.step3Headline',
    descriptionKey: 'steps.step3Description',
  },
];

export default function Steps() {
  const { t } = useTranslation();

  return (
    <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-between mb-20">
      <div className="text-center">
        <p className="mt-4 text-sm leading-7 text-gray-500 font-regular">{t('steps.title')}</p>
        <h3 className="text-3xl sm:text-5xl leading-normal font-bold tracking-tight text-gray-900">
          {t('steps.headline')}
          <span className="block xl:inline text-transparent bg-gradient-to-r from-[#9d3262] to-[#DD7336] bg-clip-text text-border" data-text={t('steps.subHeadline')}>
            {t('steps.subHeadline')}
          </span>
        </h3>
      </div>

      <div className="mt-20">
        <ul className="md:grid md:grid-cols-3 md:col-gap-10 md:row-gap-10">
          {steps.map((step) => (
            <li key={step.number} className="bg-gray-100 p-5 pb-10 text-center">
              <div className="flex flex-col items-center">
                <div className="flex-shrink-0 relative top-0 -mt-16">
                  <div className="flex items-center justify-center h-20 w-20 rounded-full bg-[#ea433b] text-white border-4 border-white text-xl font-semibold">
                    {step.number}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="text-lg leading-6 font-semibold text-gray-900">{t(step.headlineKey)}</h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">{t(step.descriptionKey)}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

