import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "signup": {
    "createAccount": "Create an account",
    "firstName": "First Name",
    "firstNamePlaceholder": "Your first name",
    "lastName": "Last Name",
    "lastNamePlaceholder": "Your last name",
    "email": "Email",
    "emailPlaceholder": "Your email",
    "password": "Password",
    "confirmPassword": "Confirm Password",
    "passwordMismatch": "Passwords do not match",
    "error": "There was an error creating the account. Please try again.",
    "alreadyHaveAccount": "Already have an account?",
    "signIn": "Sign in here"
  },
      "steps": {
        "title": "STEPS",
        "headline": "How it works ",
        "subHeadline": "for readers?",
        "step1Headline": "Get a subscription",
        "step1Description": "Choose and get a Subscriptall subscription that gives you access to premium content from multiple newspapers and media.",
        "step2Headline": "Access and Read",
        "step2Description": "Use your Subscriptall account to log in and access the websites of the affiliated media, enjoying uninterrupted reading.",
        "step3Headline": "Explore and Verify",
        "step3Description": "Read and verify news from various sources, gaining a complete and accurate view of the information."
      },
      "pricing": {
        "title": "Pricing",
        "oneSubscription": "One subscription to",
        "toRuleThemAll": "rule them all.",
        "simpleChoice": "Simple choice depending on your needs.",
        "mostPopular": "Most popular",
        "iWantIt": "I want it!",
        "generalRegional": "General + Regional",
        "generalRegionalDesc": "General and regional/local media, to keep you informed of everything that happens around you",
        "general": "General",
        "generalDesc": "Access to the main general media.",
        "premium": "Premium",
        "premiumDesc": "Full access to all types of media. Including specialized magazines, financial media and more...",
        "generalMedia": "General media",
        "regionalNewspapers": "Regional newspapers",
        "cityMedia": "The media of your city",
        "allPaywalls": "Access to all their paywalls",
        "fromSocialMedia": "From social networks or their portals",
        "cancelAnytime": "Cancel anytime",
        "regionalLocalMedia": "Regional/local media",
        "specializedMagazines": "Specialized magazines"
      },
      "newsletter": {
        "title": "Get early access",
        "placeholder": "Your e-mail",
        "subscribeButton": "Subscribe",
        "successMessage": "Thanks for subscribing!",
        "errorMessage": "There was an error. Please try again."
      },
      "mediaTitle": "For Media Outlets",
      "join": "Join",
      "subscriptall": "Subscriptall",
      "mediaDescription": "Enhance your revenue and reach with our unique subscription platform that centralizes access to premium content.",
      "incomeIncreaseTitle": "Increase in Subscription Revenue",
      "incomeIncreaseDescription": "Boost your recurring revenue through a single subscription that fairly distributes benefits among all participating media outlets.",
      "uniqueVisitsTitle": "Increase in Unique Visits",
      "uniqueVisitsDescription": "Access a broader and more diverse audience, increasing the number of unique visits to your articles and enhancing your reach.",
      "userMonetizationTitle": "Increase in User Monetization",
      "userMonetizationDescription": "Monetize users who would not normally subscribe directly to your content, increasing your revenue per reader.",
      "transparencyTitle": "Transparency in Revenue Sharing",
      "transparencyDescription": "Enjoy a transparent model in revenue distribution, based on visit and engagement metrics, ensuring fairness for all participating media outlets.",
      "integrationTitle": "Effortless Integration",
      "integrationDescription": "We want to make it very easy for you to integrate Subscriptall into your login system, if you have any questions contact our ",
      "supportTeam": "support team.",
      "step1": "Step 1",
      "step1Title": "Add the Login Button",
      "step1Description": "Add the Login with SubscriptAll button for subscriber authentication on your portal.",
      "step2": "Step 2",
      "step2Title": "Authentication",
      "step2Description": "Our authentication system uses OAuth 2.0 / OpenID / SAML protocols for quick integration with your login systems.",
      "step3": "Step 3",
      "step3Title": "Pixel Integration",
      "step3Description": "Integrate our pixel into the head of your news articles to count visits from users authenticated with Subscriptall.",
      "step4": "Step 4",
      "step4Title": "Access Subscriptall",
      "step4Description": "Access Subscriptall to view metrics and receive monthly revenue from unique reader visits.",
      "earlyAccess": "Early Access",
      "empoweredReaders": "Empowered Readers",
      "againstFakeNews": "against Fake News",
      "heroLectoresDescription": "Imagine having unlimited access to the most relevant and reliable news with a single subscription. Say goodbye to the hassles and high costs of multiple subscriptions. Now, with a single fee, you can enjoy premium content from top newspapers and media outlets. No more frustrating interruptions from paywalls; with Subscriptall, your reading experience is smooth and continuous, accessing newspapers' websites directly. Stay well-informed with diverse sources, verify facts, and form solid, well-founded opinions. Subscribe to Subscriptall and enjoy a world of information at your fingertips.",
      "heroTitlePart1": "Revolutionize access",
      "heroTitlePart2": "to your content",
      "heroSubtitle": "and multiply",
      "heroHighlight": " its reach.",
      "heroDescription": "Discover the power of a single subscription. Multiply your paying audience and increase your subscription revenue with better-informed readers.",
      "heroButton": "Meet us",
      "footerTitle": "One subscription to rule them all.",
      "contact": "Contact",
      "phone": "Phone",
      "email": "Email",
      "social": "Social",
      "socialText": "Discover news on our social networks.",
      "copyright": "© Copyright 2024 Subscriptall. All rights reserved.",
      "privacyPolicy": "Privacy Policy",
      "termsConditions": "Terms and Conditions",
      "featuresTitle": "Break the traditional subscription barrier",
      "featuresSubtitle": "Grow infinitely with",
      "featuresDescription": "Subscriptall is an authentication and subscription solution that allows readers to access premium content from digital newspapers on their own websites with a single login. Transparently distribute subscription revenue among the visited media, maximizing paywall monetization.",
      "simpleIntegration": "Simple Integration",
      "simpleIntegrationDesc": "Integrating Subscriptall into your platform is quick and hassle-free. Our system is designed to work seamlessly with your existing infrastructure, allowing you to start benefiting immediately.",
      "secureAuthentication": "Secure Authentication",
      "secureAuthenticationDesc": "Oauth authentication for maximum security. Protect your readers' information with a robust and reliable solution, ensuring the integrity and security of their personal data.",
      "customerExperience": "Improved Customer Experience",
      "customerExperienceDesc": "Readers enjoy an uninterrupted experience with a single subscription that allows them to access premium content from multiple media. This improves their satisfaction and loyalty to your brand.",
      "readerMonetization": "Increased Readers and Monetization",
      "readerMonetizationDesc": "Attract new readers, including those who would never consider a direct subscription. Monetize premium content and maximize your revenue with an expanded user base.",
      "why": "Why Subscriptall?",
      "whyDesc": "Why SubscriptAll was created",
      "media": "Media",
      "mediaDesc": "Creating value for Media",
      "readers": "Readers",
      "readersDesc": "Empowering Readers",
      "contact": "Contact",
      "login": "Log in",
      "contactUs": "We want to",
      "talkToYou": "talk to you.",
      "writeUs": "Write to us and we will get in touch with you quickly.",
      "firstName": "First Name",
      "lastName": "Last Name",
      "company": "Company",
      "email": "Email",
      "phone": "Phone",
      "country": "Country",
      "message": "Message",
      "agreePolicy": "I agree to the privacy policy",
      "privacyPolicy": "Privacy Policy",
      "send": "Send",
      "ES": "Spain",
      "PT": "Portugal",
      "FR": "France",
      "UK": "United Kingdom",

      "privacyPolicy": {
        "title": "Privacy Policy",
        "intro": "At Subscriptall, we value your privacy and are committed to protecting your personal data. This privacy policy describes how we collect, use, and share your information.",
        "section1": {
          "title": "1. Information we collect",
          "description": "We may collect and process the following data about you:",
          "item1": "Information you provide directly to us, such as your name, email address, postal address, and phone number.",
          "item2": "Information we collect automatically when you use our services, such as your IP address, browser type, and pages visited.",
          "item3": "Information from cookies and similar technologies to enhance your user experience."
        },
        "section2": {
          "title": "2. How we use your information",
          "description": "We use the information we collect to:",
          "item1": "Provide, operate, and maintain our services.",
          "item2": "Improve, personalize, and expand our services.",
          "item3": "Understand and analyze how you use our services.",
          "item4": "Develop new products, services, features, and functionality.",
          "item5": "Communicate with you, either directly or through one of our partners, including for service updates and promotional offers.",
          "item6": "Process your transactions and manage your orders."
        },
        "section3": {
          "title": "3. How we share your information",
          "description": "We do not share your personal information with third parties except in the following circumstances:",
          "item1": "With service providers who perform services on our behalf.",
          "item2": "To comply with a legal obligation or respond to a governmental request.",
          "item3": "To protect our rights, privacy, safety, or property.",
          "item4": "In connection with a merger, acquisition, restructuring, or sale of assets."
        },
        "section4": {
          "title": "4. Your rights and choices",
          "description": "You have certain rights regarding your personal information, including the rights to:",
          "item1": "Access and obtain a copy of your personal information.",
          "item2": "Correct any inaccurate or incomplete personal information.",
          "item3": "Delete your personal information under certain circumstances.",
          "item4": "Restrict or object to the processing of your personal information.",
          "item5": "Withdraw your consent at any time if the processing is based on your consent."
        },
        "section5": {
          "title": "5. Data security",
          "description": "We take reasonable measures to protect your personal information from loss, theft, and unauthorized use, access, or modification."
        },
        "section6": {
          "title": "6. Changes to this policy",
          "description": "We may update our privacy policy from time to time. We will post any changes on this page and, if the changes are significant, provide a more prominent notice."
        },
        "section7": {
          "title": "7. Contact",
          "description": "If you have any questions about this privacy policy, please contact us at info@subscriptall.com."
        }
      },
      "whySubscriptallTitle": "Why Was Subscriptall Created?",
      "whySubscriptallSubtitle": "Discover the benefits that Subscriptall brings to both media outlets and readers.",
      "uniqueSubscriptionTitle": "A Unique Subscription to Access All Content",
      "uniqueSubscriptionDescription": "Subscriptall was created to break down barriers to information access, providing a unique subscription that allows readers to access multiple media outlets.",
      "empoweredReadersTitle": "Empowered Readers",
      "empoweredReadersDescription": "In an era of misinformation, fake news, and unreliable sources, Subscriptall creates readers with the necessary, reliable, and verified information to form well-founded opinions.",
      "monetizationIncreaseTitle": "Increased Visitor Monetization",
      "monetizationIncreaseDescription": "With Subscriptall, media outlets increase their subscription revenues by monetizing a larger number of visitors who would otherwise never become individual subscribers.",
      "transparencyTitle": "Transparency",
      "transparencyDescription": "Subscription revenue distribution is done transparently and audited, based on unique monthly visits per user, increasing the regular income they would receive in the case of traditional subscriptions.",
      "calltoact": {
        "increaseRevenue": "Increase your subscription revenue",
        "writeToUs": "Write to us, ask us...",
        "makeSomethingGreat": "let's make something great.",
        "contact": "Contact"
      }

    }
  },
  es: {
    translation: {
      "signup": {
    "createAccount": "Crear una cuenta",
    "firstName": "Nombre",
    "firstNamePlaceholder": "Tu nombre",
    "lastName": "Apellido",
    "lastNamePlaceholder": "Tu apellido",
    "email": "Correo electrónico",
    "emailPlaceholder": "Tu correo electrónico",
    "password": "Contraseña",
    "confirmPassword": "Confirmar Contraseña",
    "passwordMismatch": "Las contraseñas no coinciden",
    "error": "Hubo un error al crear la cuenta. Por favor, inténtalo de nuevo.",
    "alreadyHaveAccount": "¿Ya tienes una cuenta?",
    "signIn": "Inicia sesión aquí"
  },
      "calltoact": {
        "increaseRevenue": "Incrementa tus ingresos por suscripción",
        "writeToUs": "Escríbenos, pregúntanos...",
        "makeSomethingGreat": "vamos a hacer algo grande.",
        "contact": "Contacto"
      },
      "steps": {
        "title": "PASOS",
        "headline": "¿Cómo funciona ",
        "subHeadline": "para los lectores?",
        "step1Headline": "Adquiere una suscripción",
        "step1Description": "Elige y adquiere una suscripción Subscriptall que te da acceso a contenido premium de múltiples periódicos y medios.",
        "step2Headline": "Accede y Lee",
        "step2Description": "Utiliza tu cuenta Subscriptall para iniciar sesión y acceder directamente a las webs de los medios adheridos, disfrutando de una lectura sin interrupciones.",
        "step3Headline": "Explora y Verifica",
        "step3Description": "Lee y verifica noticias de diversas fuentes, obteniendo una visión completa y precisa de la información."
      },
      "privacyPolicy": {
        "title": "Política de Privacidad",
        "intro": "En Subscriptall, valoramos su privacidad y estamos comprometidos a proteger sus datos personales. Esta política de privacidad describe cómo recopilamos, utilizamos y compartimos su información.",
        "section1": {
          "title": "1. Información que recopilamos",
          "description": "Podemos recopilar y procesar los siguientes datos sobre usted:",
          "item1": "Información que usted nos proporciona directamente, como su nombre, dirección de correo electrónico, dirección postal y número de teléfono.",
          "item2": "Información que recopilamos automáticamente cuando utiliza nuestros servicios, como su dirección IP, tipo de navegador, y páginas visitadas.",
          "item3": "Información de cookies y tecnologías similares para mejorar su experiencia de usuario."
        },
        "section2": {
          "title": "2. Cómo utilizamos su información",
          "description": "Utilizamos la información que recopilamos para:",
          "item1": "Proporcionar, operar y mantener nuestros servicios.",
          "item2": "Mejorar, personalizar y expandir nuestros servicios.",
          "item3": "Comprender y analizar cómo utiliza nuestros servicios.",
          "item4": "Desarrollar nuevos productos, servicios, características y funcionalidades.",
          "item5": "Comunicarle, ya sea directamente o a través de uno de nuestros socios, incluidas las actualizaciones de servicio y ofertas promocionales.",
          "item6": "Procesar sus transacciones y gestionar sus pedidos."
        },
        "section3": {
          "title": "3. Cómo compartimos su información",
          "description": "No compartimos su información personal con terceros excepto en las siguientes circunstancias:",
          "item1": "Con proveedores de servicios que realizan servicios en nuestro nombre.",
          "item2": "Para cumplir con una obligación legal o responder a una solicitud gubernamental.",
          "item3": "Para proteger nuestros derechos, privacidad, seguridad o propiedad.",
          "item4": "En relación con una fusión, adquisición, reestructuración o venta de activos."
        },
        "section4": {
          "title": "4. Sus derechos y opciones",
          "description": "Usted tiene ciertos derechos respecto a su información personal, incluidos los derechos a:",
          "item1": "Acceder y obtener una copia de su información personal.",
          "item2": "Corregir cualquier información personal incorrecta o incompleta.",
          "item3": "Eliminar su información personal bajo ciertas circunstancias.",
          "item4": "Restringir o oponerse al procesamiento de su información personal.",
          "item5": "Retirar su consentimiento en cualquier momento, si el procesamiento se basa en su consentimiento."
        },
        "section5": {
          "title": "5. Seguridad de los datos",
          "description": "Tomamos medidas razonables para proteger su información personal de pérdida, robo y uso no autorizado, acceso o modificación."
        },
        "section6": {
          "title": "6. Cambios en esta política",
          "description": "Podemos actualizar nuestra política de privacidad de vez en cuando. Publicaremos cualquier cambio en esta página y, si los cambios son significativos, proporcionaremos un aviso más prominente."
        },
        "section7": {
          "title": "7. Contacto",
          "description": "Si tiene alguna pregunta sobre esta política de privacidad, por favor, contáctenos en info@subscriptall.com."
        }
      },

      "pricing": {
        "title": "Pricing",
        "oneSubscription": "Una suscripción para",
        "toRuleThemAll": "gobernarlas a todas.",
        "simpleChoice": "Elección sencilla dependiendo de tus necesidades.",
        "mostPopular": "Más popular",
        "iWantIt": "¡Lo quiero!",
        "generalRegional": "General + Regional",
        "generalRegionalDesc": "Medios generalistas y regionales/locales, para estas informado de todo lo que ocurre a tu alrededor",
        "general": "General",
        "generalDesc": "Acceso a los principales medios de comunicación generalistas.",
        "premium": "Premium",
        "premiumDesc": "Acceso completo a todo tipo de medios. Incluidas revistas especializadas, medios financieros y más...",
        "generalMedia": "Medios generalistas",
        "regionalNewspapers": "Periodicos regionales",
        "cityMedia": "Los medios de tu ciudad",
        "allPaywalls": "Acceso a todos sus muros de pago",
        "fromSocialMedia": "Desde las redes sociales o sus portales",
        "cancelAnytime": "Cancela cuando quieras",
        "regionalLocalMedia": "Medios regionales/locales",
        "specializedMagazines": "Revistas especializadas"
      },
      "newsletter": {
        "title": "Accede antes que el resto",
        "placeholder": "Tu e-mail",
        "subscribeButton": "Subscribirse",
        "successMessage": "Gracias por suscribirte!",
        "errorMessage": "Hubo un error. Inténtalo de nuevo."
      },
      "mediaTitle": "Para Medios de Comunicación",
      "join": "Únete a",
      "subscriptall": "Subscriptall",
      "mediaDescription": "Potencia tus ingresos y alcance con nuestra plataforma única de suscripción que centraliza el acceso a contenido premium.",
      "incomeIncreaseTitle": "Incremento de ingresos por suscripción",
      "incomeIncreaseDescription": "Aumenta tus ingresos recurrentes mediante una suscripción única que distribuye los beneficios de manera justa entre todos los medios participantes.",
      "uniqueVisitsTitle": "Incremento de visitas únicas",
      "uniqueVisitsDescription": "Accede a un público más amplio y diverso, incrementando el número de visitas únicas a tus artículos y potenciando tu alcance.",
      "userMonetizationTitle": "Incremento de monetización por usuario",
      "userMonetizationDescription": "Monetiza a los usuarios que normalmente no se suscribirían directamente a tu contenido, aumentando tus ingresos por cada lector.",
      "transparencyTitle": "Transparencia en el reparto de ingresos",
      "transparencyDescription": "Disfruta de un modelo transparente en la distribución de ingresos, basado en métricas de visitas y engagement, asegurando equidad para todos los medios participantes.",
      "integrationTitle": "Integración sin esfuerzo",
      "integrationDescription": "Queremos que sea muy sencillo para vosotros la integración de Subscriptall en vuestro sistema de login , si tienes cualquier duda contacta con nuestro ",
      "supportTeam": "equipo de soporte.",
      "step1": "Paso 1",
      "step1Title": "Añade el botón de Login",
      "step1Description": "Añade el botón Login con SubscriptAll para la autenticación de suscriptores en tu portal.",
      "step2": "Paso 2",
      "step2Title": "Autenticación",
      "step2Description": "Nuestro sistema de autenticación utiliza los protocolos OAuth 2.0 / OpenID / SAML, para una rápida integración con tus sistemas de login",
      "step3": "Paso 3",
      "step3Title": "Integración del pixel",
      "step3Description": "Integra nuestro pixel en el head de tus noticias para contabilizar las visitas de los usuarios autenticados en Subscriptall",
      "step4": "Paso 4",
      "step4Title": "Accede a Subscriptall",
      "step4Description": "Accede a Subscriptall para ver métricas y recibe mensualmente los ingresos por las visitas unicas de los lectores",
      "earlyAccess": "Acceso Temprano",
      "empoweredReaders": "Lectores Empoderados",
      "againstFakeNews": "contra las Fake News",
      "heroLectoresDescription": "Imagina tener acceso ilimitado a las noticias más relevantes y confiables con una sola suscripción. Despídete de las complicaciones y los altos costos de múltiples suscripciones. Ahora, con una tarifa única, puedes disfrutar de contenido premium de los principales periódicos y medios de comunicación. No más interrupciones frustrantes por muros de pago; con Subscriptall, tu experiencia de lectura es fluida y continua, accediendo directamente a las webs de los periódicos. Mantente bien informado con diversas fuentes, verifica datos y forma opiniones sólidas y bien fundamentadas. Suscríbete a Subscriptall y disfruta de un mundo de información al alcance de tu mano.",
      "heroTitlePart1": "Revoluciona el acceso",
      "heroTitlePart2": "a tu contenido",
      "heroSubtitle": "y multiplica",
      "heroHighlight": " su alcance.",
      "heroDescription": "Descubre el poder de una única suscripción. Multiplica tu audiencia de pago y aumenta tus ingresos por suscripción con lectores más informados.",
      "heroButton": "Conócenos",
      "footerTitle": "Una suscripción para gobernarlas a todas.",
      "contact": "Contacto",
      "phone": "Teléfono",
      "email": "Email",
      "social": "Social",
      "socialText": "Descubre novedades en nuestras redes sociales.",
      "copyright": "© Copyright 2024 Subscriptall. Todos los derechos reservados.",
      "privacyPolicy": "Política de privacidad",
      "termsConditions": "Términos y Condiciones",
      "featuresTitle": "Rompe la barrera de la suscripción tradicional",
      "featuresSubtitle": "Crece hasta el infinito con",
      "featuresDescription": "Subscriptall es una solución de autenticación y suscripción que permite a los lectores acceder a contenido premium de diarios digitales, en sus propias webs, con un solo inicio de sesión. Distribuye de manera transparente los ingresos por suscripción entre los medios visitados, maximizando la monetización de los muros de pago. Subscriptall No es un agregador de noticias. Es una tarifa plana con la que acceder a las webs de todos los periodicos.",
      "simpleIntegration": "Integración Sencilla",
      "simpleIntegrationDesc": "Integrar Subscriptall en tu plataforma es rápido y sin complicaciones. Nuestro sistema está diseñado para funcionar perfectamente con tu infraestructura existente, permitiéndote comenzar a beneficiarte de inmediato.",
      "secureAuthentication": "Seguridad en la Autenticación",
      "secureAuthenticationDesc": "Autenticación Oauth para máxima seguridad. Protege la información de tus lectores con una solución robusta y confiable, garantizando la integridad y seguridad de sus datos personales.",
      "customerExperience": "Mejora de la Experiencia del Cliente",
      "customerExperienceDesc": "Los lectores disfrutan de una experiencia sin interrupciones con una única suscripción que les permite acceder a contenido premium de múltiples medios. Esto mejora su satisfacción y fidelidad a tu marca.",
      "readerMonetization": "Incremento de Lectores y Monetización",
      "readerMonetizationDesc": "Atrae a nuevos lectores, incluyendo aquellos que nunca considerarían una suscripción directa. Monetiza el contenido premium y maximiza tus ingresos con una base de usuarios ampliada.",
      "why": "¿Por qué Subscriptall?",
      "whyDesc": "Por qué nace SubscriptAll",
      "media": "Medios",
      "mediaDesc": "Creando valor para Medios de comunicación",
      "readers": "Lectores",
      "readersDesc": "Empoderando lectores",
      "contact": "Contactar",
      "login": "Log in",
      "contactUs": "Queremos",
      "talkToYou": "hablar contigo.",
      "writeUs": "Escríbenos y nos pondremos en contacto contigo rápidamente.",
      "firstName": "Nombre",
      "lastName": "Apellido",
      "company": "Empresa",
      "email": "Email",
      "phone": "Teléfono",
      "country": "País",
      "message": "Mensaje",
      "agreePolicy": "Acepto la política de privacidad",
      "privacyPolicy": "Política de privacidad",
      "send": "Enviar",
      "ES": "España",
      "PT": "Portugal",
      "FR": "Francia",
      "UK": "Reino Unido",
      "whySubscriptallTitle": "¿Por Qué Nace Subscriptall?",
      "whySubscriptallSubtitle": "Descubre los beneficios que Subscriptall trae tanto para los medios de comunicación como para los lectores.",
      "uniqueSubscriptionTitle": "Una suscripción unica para acceder a todo el contenido",
      "uniqueSubscriptionDescription": "Subscriptall nace para romper las barreras del acceso a la información, proporcionando una suscripción única que permite a los lectores acceder a múltiples medios de comunicación.",
      "empoweredReadersTitle": "Lectores Empoderados",
      "empoweredReadersDescription": "En una época de desinformación, fake news y fuentes no fiables, Subscriptall crea lectores con la información necesaria, fiable y contrastada, para poder tener opiniones fundadas.",
      "monetizationIncreaseTitle": "Incremento de la monetización de los visitantes",
      "monetizationIncreaseDescription": "Con Subscriptall los medios de comunicación incrementan sus ingresos por suscripción permitiendo monetizar a un mayor numero de visitantes que, de otra manera, nunca serían suscriptores unicos.",
      "transparencyTitle": "Transparencia",
      "transparencyDescription": "El reparto de ingresos por suscripción se realiza de manera transparente y auditada, mediante visitas únicas mensuales por usuario, incrementando los ingresos ordinarios que recibirian en el caso de suscripciones tradicionales."

    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es', // Idioma por defecto
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
