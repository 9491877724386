import React from 'react';
import Header from './Header'; // Asegúrate de ajustar la ruta si es necesario
import Footer from './Footer'; // Asegúrate de ajustar la ruta si es necesario
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t('privacyPolicy.title')}</h1>
          <p className="mt-4 text-lg text-gray-600">
            {t('privacyPolicy.intro')}
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section1.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section1.description')}
            <ul className="list-disc list-inside mt-2">
              <li>{t('privacyPolicy.section1.item1')}</li>
              <li>{t('privacyPolicy.section1.item2')}</li>
              <li>{t('privacyPolicy.section1.item3')}</li>
            </ul>
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section2.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section2.description')}
            <ul className="list-disc list-inside mt-2">
              <li>{t('privacyPolicy.section2.item1')}</li>
              <li>{t('privacyPolicy.section2.item2')}</li>
              <li>{t('privacyPolicy.section2.item3')}</li>
              <li>{t('privacyPolicy.section2.item4')}</li>
              <li>{t('privacyPolicy.section2.item5')}</li>
              <li>{t('privacyPolicy.section2.item6')}</li>
            </ul>
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section3.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section3.description')}
            <ul className="list-disc list-inside mt-2">
              <li>{t('privacyPolicy.section3.item1')}</li>
              <li>{t('privacyPolicy.section3.item2')}</li>
              <li>{t('privacyPolicy.section3.item3')}</li>
              <li>{t('privacyPolicy.section3.item4')}</li>
            </ul>
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section4.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section4.description')}
            <ul className="list-disc list-inside mt-2">
              <li>{t('privacyPolicy.section4.item1')}</li>
              <li>{t('privacyPolicy.section4.item2')}</li>
              <li>{t('privacyPolicy.section4.item3')}</li>
              <li>{t('privacyPolicy.section4.item4')}</li>
              <li>{t('privacyPolicy.section4.item5')}</li>
            </ul>
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section5.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section5.description')}
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section6.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section6.description')}
          </p>
          <h2 className="mt-8 text-2xl font-semibold text-gray-900">{t('privacyPolicy.section7.title')}</h2>
          <p className="mt-2 text-gray-600">
            {t('privacyPolicy.section7.description')}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

