import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Loading from './Loading'; // Importa el componente Loading
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

export default function SignUp() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Estado para el componente Loading
  const navigate = useNavigate(); // Hook de navegación

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setMessage(t('signup.passwordMismatch'));
      return;
    }

    setLoading(true); // Mostrar el componente Loading

    try {
      const response = await axios.post('https://api.subscriptall.com/api/auth/signup', formData);
      if (response.status === 201) {
        const { authToken } = response.data;
        const authResponse = await axios.post(
          `https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/token`,
          new URLSearchParams({
            grant_type: 'password',
            client_id: 'Subscriptall-web',
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: formData.email,
            password: formData.password,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        // Guarda el token en el almacenamiento local
        //localStorage.setItem('authToken', authToken);
        localStorage.setItem('access_token', authResponse.data.access_token);
        const decodedToken = jwtDecode(access_token);
          localStorage.setItem('decoded_token', JSON.stringify(decodedToken));

        // Redirige al dashboard
        navigate('/dashboard', { state: { notification: '¡Su cuenta se ha creado con éxito!' } });
      } else {
        setMessage(t('signup.error'));
      }
    } catch (error) {
      console.error('Error creating account:', error);
      setMessage(t('signup.error'));
    } finally {
      setLoading(false); // Ocultar el componente Loading
    }
  };

  return (
    <section className="flex flex-col items-center pt-6">
      {loading && <Loading />} {/* Mostrar el componente Loading si loading es true */}
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {t('signup.createAccount')}
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('signup.firstName')}
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={t('signup.firstNamePlaceholder')}
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('signup.lastName')}
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={t('signup.lastNamePlaceholder')}
                required
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('signup.email')}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={t('signup.emailPlaceholder')}
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('signup.password')}
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="••••••••"
                required
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('signup.confirmPassword')}
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="••••••••"
                required
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="w-full text-white bg-gradient-to-r from-[#DD7336] to-[#9d3262] hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {t('signup.createAccount')}
            </button>
            {message && <p className="text-red-500 text-sm mt-2">{message}</p>}
          </form>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-4">
            {t('signup.alreadyHaveAccount')}{' '}
            <a
              className="font-medium text-blue-600 hover:underline dark:text-blue-500"
              href="https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/auth?client_id=Subscriptall-web&redirect_uri=https%3A%2F%2Fwww.subscriptall.com%2Fcallback&response_type=code&scope=openid"
            >
              {t('signup.signIn')}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}


