import React from 'react';
import { RocketLaunchIcon, BoltIcon, ArrowsPointingInIcon, CubeTransparentIcon } from '@heroicons/react/20/solid';
import foto from '../images/porque.png';
import { useTranslation } from 'react-i18next';

const features = [
  {
    titleKey: 'uniqueSubscriptionTitle',
    descriptionKey: 'uniqueSubscriptionDescription',
    icon: <ArrowsPointingInIcon className="h-6 w-6 text-gray-900" />,
  },
  {
    titleKey: 'empoweredReadersTitle',
    descriptionKey: 'empoweredReadersDescription',
    icon: <BoltIcon className="h-6 w-6 text-gray-900" />,
  },
  {
    titleKey: 'monetizationIncreaseTitle',
    descriptionKey: 'monetizationIncreaseDescription',
    icon: <RocketLaunchIcon className="h-6 w-6 text-gray-900" />,
  },
  {
    titleKey: 'transparencyTitle',
    descriptionKey: 'transparencyDescription',
    icon: <CubeTransparentIcon className="h-6 w-6 text-gray-900" />,
  },
];

export default function WhySubscriptall() {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="text-center">
          <h2 className="font-bold sm:text-5xl block xl:inline text-transparent bg-gradient-to-r from-[#9d3262] to-[#DD7336] bg-clip-text text-border" data-text={t('whySubscriptallTitle')}>{t('whySubscriptallTitle')}</h2>
          <p className="mt-4 text-xl max-w-3xl mx-auto">
            {t('whySubscriptallSubtitle')}
          </p>
        </div>
        <div className="grid lg:grid-cols-2 lg:gap-8 lg:items-center mt-12">
          <div>
            <div className="space-y-12">
              {features.map((feature, index) => (
                <div className="flex" key={index}>
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md bg-white shadow-md">
                      {feature.icon}
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-900">
                      {t(feature.titleKey)}
                    </h4>
                    <p className="mt-2 text-gray-600">{t(feature.descriptionKey)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div aria-hidden="true" className="mt-10 lg:mt-0">
            <img
              style={{
                backgroundImage: `url(${foto})`,
                width: '600px',
                height: '600px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'transparent'
              }}
              className="mx-auto rounded-lg shadow-lg"
              alt="Feature"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
