import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function Newsletter() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.subscriptall.com/api/subscribe', { email });
      if (response.status === 200) {
        setMessage(t('newsletter.successMessage'));
        setEmail('');
      } else {
        setMessage(t('newsletter.errorMessage'));
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      setMessage(t('newsletter.errorMessage'));
    }
  };

  return (
    <div className="py-5 bg-[#160D11] md:py-7 lg:py-14">
      <div className="container mx-auto px-5 rounded-lg xl:max-w-screen-xl">
        <div className="p-5 rounded-lg bg-gradient-to-r from-[#9d3262] to-[#DD7336] lg:flex lg:justify-between lg:items-center lg:p-8">
          <div>
            <h1 className="text-3xl font-bold text-center text-white md:text-4xl lg:text-left">
              {t('newsletter.title')}
            </h1>
            <form className="mt-5 sm:mx-auto sm:flex sm:max-w-lg lg:mx-0" onSubmit={handleSubmit}>
              <input
                className="block w-full px-5 py-3 outline-none border rounded shadow-sm text-gray-300 border-[#3c3c3c] bg-[#121212] focus:border-[#160D11] focus:ring-1 focus:ring-[#160D11]"
                type="email"
                placeholder={t('newsletter.placeholder')}
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="w-full mt-2.5 px-5 py-3 rounded shadow-sm focus:outline-none font-medium text-[#9d3262] bg-[#160D11] sm:flex-shrink-0 sm:w-auto sm:mt-0 sm:ml-5"
                type="submit"
              >
                {t('newsletter.subscribeButton')}
              </button>
            </form>
            {message && <p className="mt-3 text-center text-white">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
