import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const products = [
  {
    name: 'General + Regional',
    description: 'Medios generalistas y regionales/locales, para estas informado de todo lo que ocurre a tu alrededor',
    price: '€ 15,99 / mes',
    features: ['Medios generalistas', 'Periodicos regionales', 'Los medios de tu ciudad'],
    groupName: 'News-Regional',
    groupID: '1010a2dd-5a06-411b-9373-2774a339681c',
    priceId: 'price_1PeHtrCyeuyBblWEbKHxtcGs',
    mostPopular: false,
  },
  {
    name: 'General',
    description: 'Acceso a los principales medios de comunicación generalistas, para poder estar realmente informado.',
    price: '€ 10,99 / mes',
    features: ['Acceso a todos sus muros de pago', 'Desde las redes sociales o sus portales', 'Cancela cuando quieras'],
    groupName: 'News-General',
    groupID: '2112eeee-834b-422d-a5e6-8d251262f541',
    priceId: 'price_1PeHkcCyeuyBblWE7iAoNyvm',
    mostPopular: true,
  },
  {
    name: 'Premium',
    description: 'Acceso completo a todo tipo de medios. Incluidas revistas especializadas, medios financieros y más...',
    price: '€ 21,99 / mes',
    features: ['Medios generalistas', 'Medios regionales/locales', 'Revistas especializadas'],
    groupName: 'News-Premium',
    groupID: 'fcfc8731-a3dc-4750-9d01-fd55959bfb8b',
    priceId: 'price_1PeHkcCyeuyedWE7iAoNyvm',
    mostPopular: false,
  },
];

const ProfileView = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userSubscription, setUserSubscription] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const decoded = jwtDecode(token);
      const roles = decoded.roles || [];

      if (roles.includes('NEWS-PREMIUM')) {
        setUserSubscription('News-Premium');
      } else if (roles.includes('NEWS-REGIONAL')) {
        setUserSubscription('News-Regional');
      } else if (roles.includes('NEWS-GENERAL')) {
        setUserSubscription('News-General');
      }
    }
  }, []);

  const handleChangeSubscription = async (priceId) => {
    setLoading(true);
    const token = localStorage.getItem('access_token');
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.sub;

    try {
      const { data } = await axios.post('https://api.subscriptall.com/api/stripe/create-checkout-session', {
        priceId,
        userId,
      });

      window.location.href = data.url;
    } catch (error) {
      console.error('Error cambiando la suscripción:', error);
      alert('Hubo un error al cambiar la suscripción.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="isolate pt-5 bg-gray-100 mx-auto mt-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {products.map((product) => {
          const isCurrentSubscription = userSubscription === product.groupName;

          return (
            <div
              key={product.name}
              className={`rounded-3xl p-8 xl:p-10 bg-white shadow-md ${product.mostPopular && userSubscription !== 'News-General' ? 'ring-2 ring-[#f4901c]' : 'ring-1 ring-gray-200'}`}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h2 id={product.name} className="text-lg font-semibold leading-8 text-gray-900">
                  {product.name}
                </h2>
                {product.mostPopular && userSubscription !== 'News-General' && (
                  <p className="rounded-full bg-[#f4901c] px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    Más popular
                  </p>
                )}
                {isCurrentSubscription && (
                  <p className="rounded-full bg-green-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    Actual
                  </p>
                )}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600 min-h-[48px]">{product.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{product.price}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600"></span>
              </p>
              <button
                onClick={() => handleChangeSubscription(product.priceId)}
                disabled={loading || isCurrentSubscription}
                className={`mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 ${
                  product.mostPopular && userSubscription !== 'News-General'
                    ? 'bg-[#f4901c] text-white hover:bg-[#f4901c] focus-visible:outline-indigo-500'
                    : 'bg-gray-900 text-white hover:bg-gray-800 focus-visible:outline-gray-500'
                } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${isCurrentSubscription && 'cursor-not-allowed opacity-50'}`}
              >
                {loading ? 'Procesando...' : isCurrentSubscription ? 'Ya la tienes' : '¡Lo quiero!'}
              </button>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {product.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-gray-900" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileView;
