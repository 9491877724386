import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import * as XLSX from 'xlsx';
import logoExcel from '../../images/logoexcel.png';

const SubscriptionsView = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get('/api/subscriptions/user'); // Actualiza la ruta para obtener suscripciones de usuarios
      setSubscriptions(response.data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(subscriptions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Subscriptions');
    XLSX.writeFile(workbook, 'subscriptions.xlsx');
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">Suscripciones</h2>
        <button onClick={handleExportToExcel}>
          <img src={logoExcel} alt="Export to Excel" style={{ width: '40px', height: '40px' }} />
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email del Usuario</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID de Suscripción en Stripe</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha de Creación</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Próxima Fecha de Pago</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo de Suscripción</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200" style={{ fontSize: '12px', fontWeight: 'normal' }}>
          {subscriptions.map(subscription => (
            <tr key={subscription.id}>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.stripe_subscription_id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.created_at}</td>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.next_payment_date}</td>
              <td className="px-6 py-4 whitespace-nowrap">{subscription.subscription_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriptionsView;
