import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircleStackIcon, AdjustmentsHorizontalIcon, RocketLaunchIcon, CursorArrowRippleIcon } from '@heroicons/react/20/solid';

const steps = [
  {
    step: 'step1',
    title: 'step1Title',
    description: 'step1Description',
    icon: <CircleStackIcon className="h-6 w-6 text-white" />,
  },
  {
    step: 'step2',
    title: 'step2Title',
    description: 'step2Description',
    icon: <AdjustmentsHorizontalIcon className="h-6 w-6 text-white" />,
  },
  {
    step: 'step3',
    title: 'step3Title',
    description: 'step3Description',
    icon: <RocketLaunchIcon className="h-6 w-6 text-white" />,
  },
  {
    step: 'step4',
    title: 'step4Title',
    description: 'step4Description',
    icon: <CursorArrowRippleIcon className="h-6 w-6 text-white" />,
  },
];

export default function Integracion() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="bg-[#160D11] dark:bg-gray-800 py-16 mt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex justify-end mb-4">
         
        </div>
        <div className="mx-auto max-w-7xl sm:text-center mb-14">
          <p className="mt-0 text-3xl font-bold tracking-tight text-white sm:text-5xl">
            {t('integrationTitle')}
          </p>
          <p className="mt-10 text-lg sm:text-center leading-8 text-gray-300">
            {t('integrationDescription')}
            <a className="text-[#f4901c] font-medium" href="/contacto">
              {t('supportTeam')}
            </a>
          </p>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {steps.map((step, index) => (
            <div key={index}>
              <div className="flex items-center text-sm font-semibold leading-6 text-[#f4901c]">
                <div className="mr-4 flex-none">{step.icon}</div>
                {t(step.step)}
                <div
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-500/80 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                  aria-hidden="true"
                ></div>
              </div>
              <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-white">
                {t(step.title)}
              </p>
              <p className="mt-1 text-base leading-7 text-gray-300 dark:text-gray-400">
                {t(step.description)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
