import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/dashboard', { state: { notification: 'El pago de tu suscripción ha sido cancelado.' } });
  }, [navigate]);

  return (
    <div>
      <h1>Pago Cancelado</h1>
      <p>El pago de tu suscripción ha sido cancelado. Si tienes alguna pregunta, por favor contacta con soporte.</p>
    </div>
  );
};

export default Cancel;
