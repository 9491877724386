import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const updateSubscription = async () => {
      const query = new URLSearchParams(window.location.search);
      const sessionId = query.get('session_id');

      if (sessionId) {
        try {
          const token = localStorage.getItem('access_token');
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.sub;

          await axios.put('https://api.subscriptall.com/api/user/update-subscription', {
            userId,
            sessionId,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          navigate('/dashboard', { state: { notification: '¡Pago realizado con éxito y suscripción actualizada!' } });
        } catch (error) {
          console.error('Error actualizando la suscripción:', error);
          navigate('/dashboard', { state: { notification: 'Hubo un error actualizando tu suscripción.' } });
        }
      }
    };

    updateSubscription();
  }, [navigate]);

  return (
    <div>
      <h1>¡Pago Exitoso!</h1>
      <p>Tu suscripción ha sido actualizada.</p>
    </div>
  );
};

export default Success;
