import React, { useState } from 'react';
import axios from '../utils/axios';

const SubscriptionDetailsModal = ({ subscription, onClose, onSave }) => {
  const [editedSubscription, setEditedSubscription] = useState({ ...subscription });

  const handleChange = (field, value) => {
    setEditedSubscription({
      ...editedSubscription,
      [field]: value,
    });
  };

  const handleSave = async () => {
    try {
      if (editedSubscription.id) {
        // Actualizar suscripción existente
        await axios.put(`/api/subscriptions/types/${subscription.id}`, editedSubscription); // Actualiza la ruta para guardar los tipos de suscripciones
      } else {
        // Crear nueva suscripción
        await axios.post('/api/subscriptions/types', editedSubscription); // Actualiza la ruta para crear los tipos de suscripciones
      }
      onSave(); // Refresh data after saving
      onClose(); // Close the modal after saving
    } catch (error) {
      console.error('Error saving subscription:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Detalles de la Suscripción</h3>
                <div className="mt-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Nombre</label>
                      <input
                        type="text"
                        value={editedSubscription.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Stripe Price ID</label>
                      <input
                        type="text"
                        value={editedSubscription.stripe_price_id}
                        onChange={(e) => handleChange('stripe_price_id', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Familia</label>
                      <input
                        type="text"
                        value={editedSubscription.family}
                        onChange={(e) => handleChange('family', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Grupo de Keycloak</label>
                      <input
                        type="text"
                        value={editedSubscription.keycloak_group}
                        onChange={(e) => handleChange('keycloak_group', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Precio Mensual</label>
                      <input
                        type="number"
                        value={editedSubscription.monthly_price}
                        onChange={(e) => handleChange('monthly_price', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Diferencia de Precio</label>
                      <input
                        type="number"
                        value={editedSubscription.price_difference}
                        onChange={(e) => handleChange('price_difference', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      onClick={onClose}
                      className="bg-gray-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={handleSave}
                      className="bg-blue-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetailsModal;
