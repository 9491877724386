import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const refreshTokenEndpoint = 'https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/token';
const clientId = process.env.REACT_APP_CLIENTID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

//console.log('Client Secret:', clientSecret); // Añadir un console log para depuración

async function refreshToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  const Scope = localStorage.getItem('scope');
  if (!refreshToken) {
    console.error('No refresh token found');
    return;
  }

  try {
    const params = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: refreshToken,
    });

    //console.log('Sending refresh token request with params:', params.toString());

    const response = await axios.post(refreshTokenEndpoint, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const { access_token, refresh_token, expires_in, refresh_expires_in } = response.data;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);

    const decodedToken = jwtDecode(access_token);
    localStorage.setItem('decoded_token', JSON.stringify(decodedToken));

    const accessTokenExpiresIn = expires_in * 1000 - 60000;
    const refreshTokenExpiresIn = refresh_expires_in * 1000 - 60000;

    if (accessTokenExpiresIn > 0) {
      setTimeout(refreshToken, accessTokenExpiresIn);
    } else if (refreshTokenExpiresIn > 0) {
      console.warn('Access token expired but refresh token is still valid, refreshing immediately');
      refreshToken();
    } else {
      console.error('Both access token and refresh token have expired');
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request made but no response received:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }
  }
}

export function initializeAuth() {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const expiresIn = (decodedToken.exp * 1000) - Date.now() - 60000;
    if (expiresIn > 0) {
      setTimeout(refreshToken, expiresIn);
    } else {
      refreshToken();
    }
  } else {
    refreshToken();
  }
}
