import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.subscriptall.com';

function DashboardView({ givenName, userId, role, grupo }) {
  const [pagesVisited, setPagesVisited] = useState(0);
  const [mediaVisited, setMediaVisited] = useState(0);
  const [subscriptionExpiry, setSubscriptionExpiry] = useState('');

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const pagesResponse = await axios.get(`${API_BASE_URL}/api/dashboard/pages-visited/${userId}`);
        console.log('Pages visited response:', pagesResponse.data);
        setPagesVisited(pagesResponse.data.pages_visited || 0);

        const mediaResponse = await axios.get(`${API_BASE_URL}/api/dashboard/media-visited/${userId}`);
        console.log('Media visited response:', mediaResponse.data);
        setMediaVisited(mediaResponse.data.media_visited || 0);

        const subscriptionResponse = await axios.get(`${API_BASE_URL}/api/dashboard/subscription-expiry/${userId}`);
        console.log('Subscription expiry response:', subscriptionResponse.data);
        setSubscriptionExpiry(subscriptionResponse.data.next_payment_date || '');
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, [userId]);

  return (
    <div className="p-8">
      <h1 className="block xl:inline text-transparent bg-clip-text bg-gradient-to-r from-[#9d3262] to-[#DD7336]">
        Bienvenido, {givenName}
      </h1>
      <div className="mt-4 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold">Tu Suscripción</h2>
        <p>{grupo}</p>
      </div>
      <div className="grid gap-4 lg:gap-8 md:grid-cols-3 p-8 pt-20">
        <div className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
              <span>Páginas visitadas</span>
            </div>
            <div className="text-3xl dark:text-gray-100">
              {pagesVisited}
            </div>
          </div>
        </div>
        <div className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
              <span>Medios visitados</span>
            </div>
            <div className="text-3xl dark:text-gray-100">
              {mediaVisited}
            </div>
          </div>
        </div>
        <div className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-400">
              <span>Suscripción activa hasta</span>
            </div>
            <div className="text-3xl dark:text-gray-100">
              {subscriptionExpiry ? new Date(subscriptionExpiry).toLocaleDateString() : 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardView;
