import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import SubscriptionDetailsModal from './SubscriptionDetailsModal';

const SubscriptionsEditView = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [editedSubscriptions, setEditedSubscriptions] = useState({});
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get('/api/subscriptions/types'); // Actualiza la ruta para obtener los tipos de suscripciones
      setSubscriptions(response.data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  const handleChange = (id, field, value) => {
    setEditedSubscriptions({
      ...editedSubscriptions,
      [id]: {
        ...editedSubscriptions[id],
        [field]: value,
      },
    });
  };

  const handleSave = async (id) => {
    try {
      await axios.put(`/api/subscriptions/types/${id}`, editedSubscriptions[id]); // Actualiza la ruta para guardar los tipos de suscripciones
      fetchSubscriptions(); // Refresh data
      setEditedSubscriptions({ ...editedSubscriptions, [id]: null });
    } catch (error) {
      console.error('Error updating subscription:', error);
    }
  };

  const handleAddSubscription = () => {
    setSelectedSubscription({
      id: null,
      name: '',
      stripe_price_id: '',
      family: '',
      keycloak_group: '',
      monthly_price: '',
      price_difference: ''
    });
  };

  const handleCloseModal = () => {
    setSelectedSubscription(null);
    fetchSubscriptions(); // Refresh data after closing the modal
  };

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-between items-center mb-4">
        <h2>Suscripciones</h2>
        <button
          onClick={handleAddSubscription}
          className="bg-gray-600 rounded-full w-48 h-10 text-white font-semibold text-sm flex gap-3 justify-center items-center"
        >
          <span>
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </span>
          <span>Añadir suscripción</span>
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stripe Price ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Family</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Keycloak Group</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monthly Price</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price Difference</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {subscriptions.map((subscription) => (
            <tr
              key={subscription.id}
              className="text-sm font-normal cursor-pointer"
              style={{ fontSize: '11px', fontWeight: 'normal' }}
              onClick={() => setSelectedSubscription(subscription)}
            >
              <td className="px-6 py-2 whitespace-nowrap">
                <input
                  type="text"
                  style={{ fontSize: '11px', fontWeight: 'normal' }}
                  value={editedSubscriptions[subscription.id]?.name || subscription.name}
                  onChange={(e) => handleChange(subscription.id, 'name', e.target.value)}
                  className="w-15 p-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="text"
                  style={{ fontSize: '11px', fontWeight: 'normal' }}
                  value={editedSubscriptions[subscription.id]?.stripe_price_id || subscription.stripe_price_id}
                  onChange={(e) => handleChange(subscription.id, 'stripe_price_id', e.target.value)}
                  className="w-15 p-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="text"
                  style={{ fontSize: '11px', fontWeight: 'normal' }}
                  value={editedSubscriptions[subscription.id]?.family || subscription.family}
                  onChange={(e) => handleChange(subscription.id, 'family', e.target.value)}
                  className="w-full p-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="text"
                  style={{ fontSize: '11px', fontWeight: 'normal' }}
                  value={editedSubscriptions[subscription.id]?.keycloak_group || subscription.keycloak_group}
                  onChange={(e) => handleChange(subscription.id, 'keycloak_group', e.target.value)}
                  className="w-15 p-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  style={{ fontSize: '11px', fontWeight: 'normal' }}
                  value={editedSubscriptions[subscription.id]?.monthly_price || subscription.monthly_price}
                  onChange={(e) => handleChange(subscription.id, 'monthly_price', e.target.value)}
                  className="w-full p-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  style={{ fontSize: '11px', fontWeight: 'normal' }}
                  value={editedSubscriptions[subscription.id]?.price_difference || subscription.price_difference}
                  onChange={(e) => handleChange(subscription.id, 'price_difference', e.target.value)}
                  className="w-full p-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {editedSubscriptions[subscription.id] && (
                  <button onClick={() => handleSave(subscription.id)} className="bg-blue-500 text-white text-sm px-3 py-1.5 rounded">
                    Save
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedSubscription && (
        <SubscriptionDetailsModal subscription={selectedSubscription} onClose={handleCloseModal} onSave={fetchSubscriptions} />
      )}
    </div>
  );
};

export default SubscriptionsEditView;
