import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CallToAction() {
  const { t } = useTranslation();

  return (
    <div className="w-full items-center lg:px-24 max-w-7xl md:px-12 mx-auto px-8 py-12 ">
      <div className="relative items-center">
        <div className="w-full justify-between lg:inline-flex lg:items-center">
          <div className="max-w-xl">
            <span className="text-sm text-rose-500 font-semibold uppercase tracking-widest">{t('calltoact.increaseRevenue')}</span>
            <p className="text-black font-extrabold mt-8 text-4xl tracking-tight">
              {t('calltoact.writeToUs')}
              <span className="block xl:inline text-transparent bg-gradient-to-r from-[#9d3262] to-[#DD7336] bg-clip-text text-border" data-text={t('calltoact.makeSomethingGreat')}>
                {t('calltoact.makeSomethingGreat')}
              </span>
            </p>
          </div>
          <div className="flex flex-col lg:ml-auto mt-12 sm:flex-row">
            <a
              className="w-full items-center inline-flex duration-200 focus:outline-none font-medium justify-center px-6 py-3 rounded-xl text-center focus-visible:outline-black lg:w-auto bg-rose-500 focus-visible:ring-black hover:bg-rose-100 hover:text-rose-500 text-white"
              href="/contacto"
            >
              {t('calltoact.contact')} &nbsp;&nbsp;
              <span className="font-bold text-2xl">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
