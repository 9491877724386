import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

const products = [
  {
    nameKey: 'generalRegional',
    descriptionKey: 'generalRegionalDesc',
    price: '€ 15,99 / mes',
    featuresKey: ['generalMedia', 'regionalNewspapers', 'cityMedia'],
    mostPopular: false,
  },
  {
    nameKey: 'general',
    descriptionKey: 'generalDesc',
    price: '€ 10,99 / mes',
    featuresKey: ['allPaywalls', 'fromSocialMedia', 'cancelAnytime'],
    mostPopular: true,
  },
  {
    nameKey: 'premium',
    descriptionKey: 'premiumDesc',
    price: '€ 21,99 / mes',
    featuresKey: ['generalMedia', 'regionalLocalMedia', 'specializedMagazines'],
    mostPopular: false,
  },
];

export default function Pricing() {
  const { t } = useTranslation();

  return (
    <div className="pt-5 bg-[#160D11]" id="pricing">
      <div className="mx-auto pb-20 mt-4 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h1 className="text-base font-semibold leading-7 text-[#f4901c]">{t('pricing.title')}</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            {t('pricing.oneSubscription')} <span className="block xl:inline text-transparent bg-gradient-to-r from-[#9d3262] to-[#DD7336] bg-clip-text text-border" data-text={t('pricing.toRuleThemAll')}>
              {t('pricing.toRuleThemAll')}
            </span>
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          {t('pricing.simpleChoice')}
        </p>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {products.map((product) => (
            <div
              key={product.nameKey}
              className={`rounded-3xl p-8 xl:p-10 ${
                product.mostPopular ? 'bg-white/5 ring-2 ring-[#f4901c]' : 'ring-1 ring-white/10'
              }`}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h2 id={product.nameKey} className="text-lg font-semibold leading-8 text-white">
                  {t(`pricing.${product.nameKey}`)}
                </h2>
                {product.mostPopular && (
                  <p className="rounded-full bg-[#f4901c] px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    {t('pricing.mostPopular')}
                  </p>
                )}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-300">{t(`pricing.${product.descriptionKey}`)}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-white">{product.price}</span>
                <span className="text-sm font-semibold leading-6 text-gray-300"></span>
              </p>
              <a
                href="/signin"
                aria-describedby={product.nameKey}
                className={`mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 ${
                  product.mostPopular
                    ? 'bg-[#f4901c] text-white hover:bg-[#f4901c] focus-visible:outline-indigo-500'
                    : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
              >
                {t('pricing.iWantIt')}
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                {product.featuresKey.map((featureKey) => (
                  <li key={featureKey} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                    {t(`pricing.${featureKey}`)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


