//test 30 Julio 18:59
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import ClientDashboard from './components/client/ClientDashboard';
import ContactForm from './components/Contactform';
import Hero from './components/Hero';
import Features from './components/Features';
import Steps from './components/Steps';
import Pricing from './components/Pricing';
import Calltoact from './components/Calltoact';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import Soon from './components/Soon';
import Callback from './components/Callback';
import WhySubscriptall from './components/WhySubscriptall';
import Medios from './components/Medios';
import Integracion from './components/Integracion';
import Lectores from './components/Herolectores';
import Headerdark from './components/Headerdark';
import Newsletter from './components/Newsletter';
import Success from './components/Sucess';
import Cancel from './components/Cancel';
import Signin from './components/Signin';

const ProtectedRoute = ({ element, requiredRole }) => {
  const token = localStorage.getItem('access_token');
  const userRoles = JSON.parse(localStorage.getItem('decoded_token'))?.roles || [];

  if (!token) {
    return <Navigate to="/signin" replace />;
  }

  if (requiredRole && !userRoles.includes(requiredRole)) {
    return <Navigate to="/signin" replace />;
  }

  return element;
};

function App() {
  return (
    <Router>
      <div className="bg-white min-h-screen">
        <div className="w-full">
          <Routes>
            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/admin-dashboard" element={<ProtectedRoute element={<AdminDashboard />} requiredRole="admin" />} />
            <Route path="/client-dashboard" element={<ProtectedRoute element={<ClientDashboard />} requiredRole="client_manager" />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/contacto" element={<><Header /><ContactForm /><Footer /></>} />
            <Route path="/privacy" element={<><Header /><PrivacyPolicy /><Footer /></>} />
            <Route path="/soon" element={<><Header /><Soon /><Newsletter /><Footer /></>} />
            <Route path="/" element={<><Header /><Hero /><Features /><Steps /><Pricing /><Calltoact /><Footer /></>} />
            <Route path="/why" element={<><Header /><WhySubscriptall /><Footer /></>} />
            <Route path="/medios" element={<><Header /><Medios /><Integracion /><Calltoact /><Footer /></>} />
            <Route path="/lectores" element={<><Headerdark /><Lectores /><Newsletter /><Pricing /><Footer /></>} />
            <Route path="/signin" element={<><Header /><Signin /><Footer /></>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
