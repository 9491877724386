import React from 'react';
import { useTranslation } from 'react-i18next';
import { UsersIcon, EyeIcon, CurrencyDollarIcon, ScaleIcon } from '@heroicons/react/20/solid';

const features = [
  {
    title: 'incomeIncreaseTitle',
    description: 'incomeIncreaseDescription',
    icon: <CurrencyDollarIcon className="h-6 w-6 text-white" />
  },
  {
    title: 'uniqueVisitsTitle',
    description: 'uniqueVisitsDescription',
    icon: <EyeIcon className="h-6 w-6 text-white" />
  },
  {
    title: 'userMonetizationTitle',
    description: 'userMonetizationDescription',
    icon: <UsersIcon className="h-6 w-6 text-white" />
  },
  {
    title: 'transparencyTitle',
    description: 'transparencyDescription',
    icon: <ScaleIcon className="h-6 w-6 text-white" />
  }
];

export default function Medios() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
          <h2 className="text-base font-semibold leading-7 text-white bg-[#DD7336] px-3 rounded-lg uppercase mb-4 lg:mb-8">
            {t('mediaTitle')}
          </h2>
          <h1 className="lg:text-7xl text-4xl md:text-5xl font-bold tracking-tight text-gray-900 text-center">
            {t('join')} <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#9d3262] to-[#DD7336]">{t('subscriptall')}</span>
          </h1>
          <p className="mt-6 text-md text-gray-600 max-w-lg text-center">
            {t('mediaDescription')}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature, index) => (
              <div key={index} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-12 w-12 items-center justify-center rounded-lg bg-[#9d3262]">
                    {feature.icon}
                  </div>
                  {t(feature.title)}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{t(feature.description)}</dd>
              </div>
            ))}
          </dl>
        </div> 
      </div>
    </div>
  );
}
