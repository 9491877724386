import React, { useState } from 'react';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react';
import {
  Bars3Icon,
  PuzzlePieceIcon,
  NewspaperIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Asegúrate de importar la configuración de i18next
import logo from '../images/logosubsall.png';
import esFlag from '../images/es-flag.png'; // Asegúrate de tener la imagen de la bandera española
import enFlag from '../images/en-flag.png'; // Asegúrate de tener la imagen de la bandera inglesa
import { Link } from 'react-router-dom';

const products = [
  { nameKey: 'why', descriptionKey: 'whyDesc', href: '/why', icon: PuzzlePieceIcon },
  { nameKey: 'media', descriptionKey: 'mediaDesc', href: '/medios', icon: NewspaperIcon },
  { nameKey: 'readers', descriptionKey: 'readersDesc', href: '/lectores', icon: UserGroupIcon },
];

const callsToAction = [
  { nameKey: 'contact', href: '/contacto', icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'es');

  const handleLoginClick = () => {
    window.location.href = 'https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/auth?client_id=Subscriptall-web&redirect_uri=https%3A%2F%2Fwww.subscriptall.com%2Fcallback&response_type=code&scope=openid';
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Subscriptall</span>
            <img className="h-12 w-auto" src={logo} alt="Subscriptall Logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-14">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              {t('why')}
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.nameKey}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="flex-auto">
                      <a href={item.href} className="block font-semibold text-gray-900">
                        {t(item.nameKey)}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">{t(item.descriptionKey)}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <a
                    key={item.nameKey}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                  >
                    <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {t(item.nameKey)}
                  </a>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
          <Link to="/contacto" className="text-sm font-semibold leading-6 text-gray-900">
            {t('contact')}
          </Link>
        </PopoverGroup>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
          <button onClick={() => changeLanguage('es')} className="flex items-center">
            <img src={esFlag} alt="Español" className="h-6 w-6 rounded-full mr-1" />
            <span className="text-sm text-gray-900">Español</span>
          </button>
          <button onClick={() => changeLanguage('en')} className="flex items-center">
            <img src={enFlag} alt="English" className="h-6 w-6 rounded-full mr-1" />
            <span className="text-sm text-gray-900">English</span>
          </button>
          <button onClick={handleLoginClick} className="w-48 h-25 flex items-center justify-center rounded-lg border border-transparent bg-gradient-to-r from-[#DD7336] to-[#9d3262] text-base font-medium text-white hover:bg-blue-700">
            {t('login')} <span aria-hidden="true"> &rarr;</span>
          </button>
        </div>
      </nav>
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Subscriptall</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt="Subscriptall Logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        {t('why')}
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <DisclosureButton
                            key={item.nameKey}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {t(item.nameKey)}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/contacto"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t('contact')}
                </Link>
              </div>
              <div className="py-6">
                <button
                  onClick={handleLoginClick}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t('login')}
                </button>
              </div>
              <div className="py-6">
                <button onClick={() => changeLanguage('es')} className="flex items-center w-full justify-center py-2">
                  <img src={esFlag} alt="Español" className="h-6 w-6 rounded-full mr-1" />
                  <span className="text-sm text-gray-900">Español</span>
                </button>
                <button onClick={() => changeLanguage('en')} className="flex items-center w-full justify-center py-2">
                  <img src={enFlag} alt="English" className="h-6 w-6 rounded-full mr-1" />
                  <span className="text-sm text-gray-900">English</span>
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
