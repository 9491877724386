// components/Loading.js
import React from 'react';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
      <div className="flex flex-row gap-2">
        <div className="w-4 h-4 rounded-full bg-[#DD7336] animate-bounce"></div>
        <div className="w-4 h-4 rounded-full bg-[#ea4538] animate-bounce [animation-delay:-.3s]"></div>
        <div className="w-4 h-4 rounded-full bg-[#9d3262] animate-bounce [animation-delay:-.5s]"></div>
      </div>
    </div>
  );
};

export default Loading;
