import React from 'react';

const SettingsView = ({ setActiveView }) => {
  return (
    <div className="flex flex-col">
      <button
        onClick={() => setActiveView('editSubscriptions')}
        className="bg-gray-600 rounded-full w-60 h-12 text-white font-semibold px-2.5"
      >
        <div className="flex gap-3 justify-center items-center">
          <span>
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
          </span>
          <span className="text-sm whitespace-nowrap">Editar suscripciones</span>
        </div>
      </button>
    </div>
  );
};

export default SettingsView;

