import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  PuzzlePieceIcon,
  NewspaperIcon,
  UserGroupIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
import logo from '../images/logosubsall.png';  // Importa tu logo
import { Link } from 'react-router-dom';

const products = [
  { name: 'why', description: 'whyDescription', href: '/why', icon: PuzzlePieceIcon },
  { name: 'medios', description: 'mediosDescription', href: '/medios', icon: NewspaperIcon },
  { name: 'lectores', description: 'lectoresDescription', href: '/lectores', icon: UserGroupIcon },
];
const callsToAction = [
  { name: 'contact', href: '/contacto', icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const { t, i18n } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLoginClick = () => {
    window.location.href = 'https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/auth?client_id=Subscriptall-web&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback&response_type=code&scope=openid';
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="bg-[#160D11]">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Subscriptall</span>
            <img className="h-12 w-auto" src={logo} alt="Subscriptall Logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-14">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-100">
              {t('whatIsSubscriptAll')}
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-[#160D11] shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-900"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-900 group-hover:bg-[#160D11]">
                      <item.icon className="h-6 w-6 text-gray-100 group-hover:text-[#DD7336]" aria-hidden="true" />
                    </div>
                    <div className="flex-auto">
                      <a href={item.href} className="block font-semibold text-gray-100">
                        {t(item.name)}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-300">{t(item.description)}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-700 bg-gray-900">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-100 hover:bg-gray-800"
                  >
                    <item.icon className="h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
                    {t(item.name)}
                  </a>
                ))}
              </div>
            </PopoverPanel>
          </Popover>

          <Link to="/contacto" className="text-sm font-semibold leading-6 text-gray-100">
            {t('contact')}
          </Link>
        </PopoverGroup>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center space-x-4">
          <button onClick={() => changeLanguage('es')} className="text-gray-100">
            ES
          </button>
          <button onClick={() => changeLanguage('en')} className="text-gray-100">
            EN
          </button>
          <button onClick={handleLoginClick} className="w-48 h-25 flex items-center justify-center rounded-lg border border-transparent bg-gradient-to-r from-[#DD7336] to-[#9d3262] text-base font-medium text-white hover:bg-blue-700">
            {t('login')} <span aria-hidden="true"> &rarr;</span>
          </button>
        </div>
      </nav>
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#160D11] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Subscriptall</span>
              <img className="h-8 w-auto" src={logo} alt="Subscriptall Logo" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-100"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-100 hover:bg-gray-900">
                        {t('whatIsSubscriptAll')}
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-100 hover:bg-gray-900"
                          >
                            {t(item.name)}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Link
                  to="/contacto"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 hover:bg-gray-900"
                >
                  {t('contact')}
                </Link>
              </div>
              <div className="py-6">
                <button
                  onClick={handleLoginClick}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-100 hover:bg-gray-900"
                >
                  {t('login')}
                </button>
              </div>
              <div className="py-6 flex justify-center">
                <button onClick={() => changeLanguage('es')} className="text-gray-100 mx-2">
                  ES
                </button>
                <button onClick={() => changeLanguage('en')} className="text-gray-100 mx-2">
                  EN
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
