import React from 'react';
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function Features() {
  const { t } = useTranslation();

  const features = [
    {
      name: t('simpleIntegration'),
      description: t('simpleIntegrationDesc'),
      icon: CodeBracketIcon,
    },
    {
      name: t('secureAuthentication'),
      description: t('secureAuthenticationDesc'),
      icon: LockClosedIcon,
    },
    {
      name: t('customerExperience'),
      description: t('customerExperienceDesc'),
      icon: ArrowPathIcon,
    },
    {
      name: t('readerMonetization'),
      description: t('readerMonetizationDesc'),
      icon: FingerPrintIcon,
    },
  ];

  return (
    <div className="bg-white py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-[#9d3262]">
            {t('featuresTitle')}
          </h2>
          <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t('featuresSubtitle')}{' '}
            <span className="block xl:inline text-transparent bg-gradient-to-r from-[#9d3262] to-[#DD7336] bg-clip-text text-border" data-text="Subscriptall">
              Subscriptall
            </span>{' '}
            🚀
          </p>
          <p className="mt-4 text-lg leading-7 text-gray-600">
            {t('featuresDescription')}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-xl font-bold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#ea433b]">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
