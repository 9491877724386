import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';

const UserDetailsModal = ({ user, onClose }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editedDetails, setEditedDetails] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`/api/admin/users/${user.keycloak_user_id}`);
        setUserDetails(response.data);
        setEditedDetails(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching user details');
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [user]);

  const handleChange = (field, value) => {
    setEditedDetails({
      ...editedDetails,
      [field]: value,
    });
  };

  const handleSave = async () => {
    try {
      await axios.put(`/api/admin/users/${user.keycloak_user_id}`, editedDetails);
      onClose(); // Close the modal after saving
    } catch (error) {
      setError('Error updating user details');
    }
  };

  const handleDeactivate = async () => {
    try {
      await axios.post(`/api/admin/users/${user.keycloak_user_id}/deactivate`);
      onClose(); // Close the modal after deactivating
    } catch (error) {
      setError('Error deactivating user');
    }
  };

  const handleChangePassword = async () => {
    try {
      await axios.post(`/api/admin/users/${user.keycloak_user_id}/change-password`, { newPassword: 'new_password' });
      onClose(); // Close the modal after changing the password
    } catch (error) {
      setError('Error changing password');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/admin/users/${user.keycloak_user_id}`);
      onClose(); // Close the modal after deleting
    } catch (error) {
      setError('Error deleting user');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Detalles del Usuario</h3>
                <div className="mt-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Nombre</label>
                      <input
                        type="text"
                        value={editedDetails.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        value={editedDetails.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Subscription Type</label>
                      <input
                        type="text"
                        value={editedDetails.subscription_type}
                        onChange={(e) => handleChange('subscription_type', e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">ID de Keycloak</label>
                      <input
                        type="text"
                        value={editedDetails.keycloak_user_id}
                        readOnly
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      onClick={onClose}
                      className="bg-gray-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={handleSave}
                      className="bg-blue-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Guardar
                    </button>
                    <button
                      onClick={handleDeactivate}
                      className="bg-red-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Desactivar
                    </button>
                    <button
                      onClick={handleChangePassword}
                      className="bg-yellow-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Cambiar Contraseña
                    </button>
                    <button
                      onClick={() => setShowDeleteConfirmation(true)}
                      className="bg-red-500 text-white text-sm px-3 py-1.5 rounded"
                    >
                      Borrar
                    </button>
                  </div>
                  {showDeleteConfirmation && (
                    <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
                      <div className="bg-white p-6 rounded shadow-md">
                        <p className="mb-4">¿Estás seguro de que deseas eliminar este usuario?</p>
                        <div className="flex justify-end space-x-2">
                          <button
                            onClick={() => setShowDeleteConfirmation(false)}
                            className="bg-gray-500 text-white text-sm px-3 py-1.5 rounded"
                          >
                            Cancelar
                          </button>
                          <button
                            onClick={handleDelete}
                            className="bg-red-500 text-white text-sm px-3 py-1.5 rounded"
                          >
                            Borrar
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsModal;

