import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import * as XLSX from 'xlsx';
import logoExcel from '../../images/logoexcel.png';

const IncomeReportView = () => {
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [incomeReports, setIncomeReports] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (month && year) {
      fetchIncomeReports();
    }
  }, [month, year]);

  const fetchIncomeReports = async () => {
    try {
      const response = await axios.get(`/api/income?month=${month}&year=${year}`);
      setIncomeReports(response.data);
    } catch (error) {
      console.error('Error fetching income reports:', error);
      setError('Error fetching income reports');
    }
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(incomeReports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Income Report');
    XLSX.writeFile(workbook, 'income_report.xlsx');
  };

  const formatNumber = (value) => {
    return value !== null && value !== undefined ? value.toFixed(2) : '0.00';
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <label className="text-xl font-semibold mr-2">Mes:</label>
          <select value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="">Selecciona un mes</option>
            <option value="1">Enero</option>
            <option value="2">Febrero</option>
            <option value="3">Marzo</option>
            <option value="4">Abril</option>
            <option value="5">Mayo</option>
            <option value="6">Junio</option>
            <option value="7">Julio</option>
            <option value="8">Agosto</option>
            <option value="9">Septiembre</option>
            <option value="10">Octubre</option>
            <option value="11">Noviembre</option>
            <option value="12">Diciembre</option>
          </select>
          <label className="ml-4 text-xl font-semibold mr-2">Año:</label>
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="YYYY"
            min="2000"
            max="2100"
            className="border rounded px-2 py-1"
          />
        </div>
        <button onClick={handleExportToExcel}>
          <img src={logoExcel} alt="Export to Excel" style={{ width: '40px', height: '40px' }} />
        </button>
      </div>
      {error && <p className="text-red-500">{error}</p>}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cliente</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ingreso Total</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Nº de Visitas</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200" style={{ fontSize: '12px', fontWeight: 'normal' }}>
          {incomeReports.map(report => (
            <tr key={report.client_id}>
              <td className="px-6 py-4 whitespace-nowrap">{report.client_name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{formatNumber(report.total_income)}</td>
              <td className="px-6 py-4 whitespace-nowrap">{report.total_visits || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncomeReportView;


