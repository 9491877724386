import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeAuth } from './Auth'; // Importa la función de inicialización

// Inicializa la autenticación al inicio de la aplicación
initializeAuth();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
