import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom'; // Usar useNavigate y useLocation
import logo from '../images/logosubsall.png'; 
import { Squares2X2Icon, CreditCardIcon, GlobeAltIcon, ArrowRightOnRectangleIcon, Bars3Icon } from '@heroicons/react/24/outline';

import DashboardView from './dash/DashboardView';
import ProfileView from './dash/ProfileView';
import SettingsView from './dash/SettingsView';

function Dashboard() {
  const [givenName, setGivenName] = useState('');
  const [userId, setUserId] = useState('');
  const [role, setRole] = useState('');
  const [grupo, setGrupo] = useState('');
  const [activeView, setActiveView] = useState('dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notification, setNotification] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        handleLogout();
        navigate('/'); // Redirige a la página de inicio de sesión
      } else {
        setGivenName(decoded.given_name);
        setUserId(decoded.sub);
        setRole(decoded.roles[0]);
        setGrupo(decoded.grupos[0]);
      }
    } else {
      handleLogout();
      navigate('/'); // Redirige a la página de inicio de sesión si no hay token
    }

    // Manejar notificaciones basadas en la ubicación
    if (location.state && location.state.notification) {
      setNotification(location.state.notification);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [navigate, location]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  const renderView = () => {
    switch (activeView) {
      case 'dashboard':
        return <DashboardView givenName={givenName} userId={userId} role={role} grupo={grupo}/>;
      case 'profile':
        return <ProfileView />;
      case 'settings':
        return <SettingsView />;
      case 'logout':
        handleLogout();
        return null;
      default:
        return <DashboardView givenName={givenName} userId={userId} role={role} />;
    }
  };

  return (
    <div className="min-h-screen flex">
      <aside className={`fixed top-0 left-0 z-40 w-64 h-screen pt-0 transition-transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}>
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <a href="/" className="text-white flex items-center space-x-2 px-4 pt-2">
            <img src={logo} alt="Logo" className="h-25 w-15"/>
          </a>
          <ul className="space-y-2 font-medium pt-10">
            <li>
              <button onClick={() => setActiveView('dashboard')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <Squares2X2Icon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Dashboard</span>
              </button>
            </li>
            <li>
              <button onClick={() => setActiveView('profile')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <CreditCardIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Suscripción</span>
                
              </button>
            </li>
            <li>
              <button onClick={() => setActiveView('settings')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <GlobeAltIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Cuenta</span>
              </button>
            </li>
            <li>
              <a href="https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/logout" onClick={handleLogout} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <ArrowRightOnRectangleIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>

      <div className="sm:hidden fixed top-0 left-0 z-50 p-4">
        <button onClick={() => setSidebarOpen(!sidebarOpen)} className="text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 p-2 rounded-lg">
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-1 p-10 bg-gray-100 text-2xl font-bold sm:ml-64">
        {notification && (
          <div className="mb-4 p-4 text-sm text-white bg-blue-500 rounded-lg">
            {notification}
          </div>
        )}
        {renderView()}
      </div>
    </div>
  );
}

export default Dashboard;
