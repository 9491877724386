import React, { useState, useEffect } from 'react';
import axios from '../utils/axios'; // Importa la instancia de axios configurada
import UserDetailsModal from './UserDetailsModal'; // Importa el componente del modal
import UserCreateModal from './UserCreateModal'; // Importa el nuevo componente del modal de creación

const UsersView = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // Estado para el modal de creación

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/admin/users');
      if (Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        console.error('La respuesta no es un array:', response.data);
        setError('La respuesta no es un array');
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users');
      setUsers([]);
    }
  };

  const handleRowClick = async (userId) => {
    try {
      const response = await axios.get(`/api/admin/users/${userId}`);
      setSelectedUser(response.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Error fetching user details');
    }
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  const handleCreateUser = async (newUser) => {
    try {
      await axios.post('/api/admin/users', newUser);
      fetchUsers(); // Refresca la lista de usuarios después de crear uno nuevo
      setIsCreateModalOpen(false); // Cierra el modal de creación
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Error creating user');
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Usuarios</h2>

        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="bg-gray-600 rounded-full w-48 h-10 text-white font-semibold text-sm flex gap-3 justify-center items-center"
        >
          <span>
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </span>
          <span>Añadir Usuario</span>
        </button> 
      </div>
      {error && <p className="text-red-500">{error}</p>}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID de Keycloak</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suscripción</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map(user => (
            <tr
              key={user.keycloak_user_id}
              className="text-sm font-normal cursor-pointer"
              style={{ fontSize: '13px', fontWeight: 'normal' }}
              onClick={() => handleRowClick(user.keycloak_user_id)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.keycloak_user_id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.subscription_type}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedUser && (
        <UserDetailsModal user={selectedUser} onClose={handleCloseModal} />
      )}
      {isCreateModalOpen && (
        <UserCreateModal onClose={() => setIsCreateModalOpen(false)} onSave={handleCreateUser} />
      )}
    </div>
  );
};

export default UsersView;

