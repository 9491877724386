import React from 'react';

const ClientDashboard = () => {
  return (
    <div>
      <h1>Client Dashboard</h1>
      {/* Aquí va el contenido específico del cliente */}
    </div>
  );
};

export default ClientDashboard;
