import React, { useState, useEffect } from 'react';

export default function Soon() {
  const calculateTimeLeft = () => {
    const difference = +new Date(`2024-12-31T00:00:00`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="w-full h-screen bg-gradient-to-bl from-[#9d3262] to-[#DD7336] flex flex-col justify-center items-center text-white">
      <h1 className="text-5xl">¡Ya estamos <b>casi</b> aquí!</h1>
      <p>Mantente alerta para cambiar tu manera de informarte!</p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 mt-10 lg:mt-20">
        <div className="bg-transparent border text-center">
          <p className="text-5xl px-10 py-5">{timeLeft.days}</p>
          <hr />
          <p className="px-10 py-5">dias</p>
        </div>

        <div className="bg-transparent border text-center">
          <p className="text-5xl px-10 py-5">{timeLeft.hours}</p>
          <hr />
          <p className="px-10 py-5">horas</p>
        </div>

        <div className="bg-transparent border text-center">
          <p className="text-5xl px-10 py-5">{timeLeft.minutes}</p>
          <hr />
          <p className="px-10 py-5">minutos</p>
        </div>
        <div className="bg-transparent border text-center">
          <p className="text-5xl px-10 py-5">{timeLeft.seconds}</p>
          <hr />
          <p className="px-10 py-5">segundos</p>
        </div>
      </div>
    </div>
  );
}
