import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logosubsall.png';
import { Squares2X2Icon, UserIcon, CreditCardIcon, ChartBarIcon, Bars3Icon, CogIcon } from '@heroicons/react/24/outline';

import UsersView from './UsersView';
import ClientsView from './ClientsView';
import SubscriptionsView from './SubscriptionsView';
import IncomeReportView from './IncomeReportView';
import SettingsView from './SettingsView'; // Importar el nuevo componente
import SubscriptionsEditView from './SubscriptionsEditView'; // Importar el nuevo componente

function AdminDashboard() {
  const [activeView, setActiveView] = useState('users');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Manejar notificaciones basadas en la ubicación
    if (location.state && location.state.notification) {
      setNotification(location.state.notification);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [navigate, location]);

  const renderView = () => {
    switch (activeView) {
      case 'users':
        return <UsersView />;
      case 'clients':
        return <ClientsView />;
      case 'subscriptions':
        return <SubscriptionsView />;
      case 'incomeReport':
        return <IncomeReportView />;
      case 'settings':
        return <SettingsView setActiveView={setActiveView} />; // Pasar setActiveView como prop
      case 'editSubscriptions':
        return <SubscriptionsEditView />;
      default:
        return <UsersView />;
    }
  };

  return (
    <div className="min-h-screen flex">
      <aside className={`fixed top-0 left-0 z-40 w-64 h-screen pt-0 transition-transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}>
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <a href="/" className="text-white flex items-center space-x-2 px-4 pt-2">
            <img src={logo} alt="Logo" className="h-25 w-15" />
          </a>
          <ul className="space-y-2 font-medium pt-10">
            <li>
              <button onClick={() => setActiveView('users')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <UserIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Usuarios</span>
              </button>
            </li>
            <li>
              <button onClick={() => setActiveView('clients')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <Squares2X2Icon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Clientes</span>
              </button>
            </li>
            <li>
              <button onClick={() => setActiveView('subscriptions')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <CreditCardIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Suscripciones</span>
              </button>
            </li>
            <li>
              <button onClick={() => setActiveView('incomeReport')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <ChartBarIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Reporte de Ingresos</span>
              </button>
            </li>
            <li>
              <button onClick={() => setActiveView('settings')} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <CogIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Ajustes</span>
              </button>
            </li>
          </ul>
        </div>
      </aside>

      <div className="sm:hidden fixed top-0 left-0 z-50 p-4">
        <button onClick={() => setSidebarOpen(!sidebarOpen)} className="text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 p-2 rounded-lg">
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-1 p-10 bg-gray-100 text-2xl font-bold sm:ml-64">
        {renderView()}
      </div>
    </div>
  );
}

export default AdminDashboard;
