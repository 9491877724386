import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import ClientDetailsModal from './ClientDetailsModal';

const ClientsView = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('/api/clients');
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleAddClient = () => {
    setSelectedClient({
      id: null,
      name: '',
      industry: '',
      keycloak_group: '',
      contact_email: '',
    });
  };

  const handleCloseModal = () => {
    setSelectedClient(null);
    fetchClients(); // Refresh data after closing the modal
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">Clientes</h2>
        <button
          onClick={handleAddClient}
          className="bg-gray-600 rounded-full w-48 h-10 text-white font-semibold text-sm flex gap-3 justify-center items-center"
        >
          <span>
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </span>
          <span>Añadir cliente</span>
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Industria</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grupo de Keycloak</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email de Contacto</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {clients.map((client) => (
            <tr
              key={client.id}
              className="text-sm font-normal cursor-pointer"
              style={{ fontSize: '13px', fontWeight: 'normal' }}
              onClick={() => setSelectedClient(client)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{client.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{client.industry}</td>
              <td className="px-6 py-4 whitespace-nowrap">{client.keycloak_group}</td>
              <td className="px-6 py-4 whitespace-nowrap">{client.contact_email}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedClient && (
        <ClientDetailsModal client={selectedClient} onClose={handleCloseModal} onSave={fetchClients} />
      )}
    </div>
  );
};

export default ClientsView;
