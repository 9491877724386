import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    const fetchToken = async () => {
      try {
        const response = await axios.post('https://admin.subscriptall.com/realms/Subscriptall/protocol/openid-connect/token', new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: 'Subscriptall-web',
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          code,
          redirect_uri: 'https://www.subscriptall.com/callback',
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        const { access_token, refresh_token } = response.data;
        if (access_token && refresh_token) {
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('refresh_token', refresh_token);

          const decodedToken = jwtDecode(access_token);
          localStorage.setItem('decoded_token', JSON.stringify(decodedToken));

          const userRoles = decodedToken.roles;

          if (userRoles.includes('admin')) {
            navigate('/admin-dashboard');
          } else if (userRoles.includes('client_manager')) {
            navigate('/client-dashboard');
          } else {
            navigate('/dashboard');
          }
        } else {
          throw new Error('Token response does not contain access_token or refresh_token');
        }
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    if (code) {
      fetchToken();
    } else {
      console.error('No authorization code found');
    }
  }, [location, navigate]);

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
};

export default Callback;
